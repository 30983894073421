import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ProjectCard = makeShortcode("ProjectCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2 tabIndex="0" className="projects-title" style={{
      "textAlign": "center"
    }}> Featured projects</h2>
    <ProjectCard tabIndex="0" link="https://register.draftprogram.com/en" bg="" mdxType="ProjectCard">
    <h3 tabIndex="0">Application system for the Draft Program®</h3>
    <p tabIndex="0">
        Application form and management system for the Draft Program® -innovation competition. The organizers can eq. create new competition events, filter applications and respond to them.
    </p>
    </ProjectCard>
    <ProjectCard tabIndex="0" link="https://keski.finna.fi/Content/kirjastot" bg="" mdxType="ProjectCard">
    <h3 tabIndex="0">Online library for the Keski Libraries</h3>
    <p tabIndex="0">
        Dynamic user interface for browsing libraries schedules, services and other information.
        Content management system for producing news and events, implementing public API for fetching them.
    </p>
    </ProjectCard>
    <ProjectCard tabIndex="0" link="https://biotalousosaaja.karelia.fi/en/" bg="" mdxType="ProjectCard">
    <h3 tabIndex="0">Portal for all things bioeconomy</h3>
    <p tabIndex="0">
        National collection of all the bioeconomy-related studies available in Finnish universities and universities of applied sciences.
        The portal features info pages,  a blog, a contact form and a course search. The created courses are automatically marked to be open and closed based on the enrollment periods.
    </p>
    </ProjectCard>
    <ProjectCard tabIndex="0" link="https://web.karelia.fi/opintokuraattorin-ajanvaraus/#en/" bg="" mdxType="ProjectCard">
    <h3 tabIndex="0">Student Welfare Officer reservation</h3>
    <p tabIndex="0">
        The students of Karelia University of Applied Sciences can now easily book appointments and receive automated confirmation messages and calendar events. Intuitive creation and management of the appointment schedules has led to significant time savings for the associated staff.
    </p>
    </ProjectCard>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      