import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2 tabIndex="0" className="contact-title">Looking for a new home</h2>
    <small tabIndex="0" style={{
      "fontSize": ".89em",
      "textAlign": "center"
    }}>home (noun); <i>someone's or something's place of origin, or the place where a person feels they belong</i></small>
    <p tabIndex="0">While I'm self-motivated and capable of doing many things, I see greater value in being part of a team.</p>
    <p tabIndex="0">Ideally, a place for my professional growth would feature some of the following principles:</p>
    <ul tabIndex="0" style={{
      "listStyle": "square"
    }}>
    <li>
         Learning is the key
    </li>
    <li>
         Quality over quantity
    </li>
    <li>
         Mentoring relationships
    </li>
    <li>
         Agile methodology
    </li>
    <li>
         Remote work opportunities
    </li>
    <li>
         Work-life balance
    </li>
    </ul>
    <h3 tabIndex="0" style={{
      "marginTop": "4em",
      "marginBottom": "1em",
      "letterSpacing": ".22em"
    }}>Sparking curiosity?</h3>
    <p tabIndex="0" className="spark-links">Check out my <a download href="Olli_Suutari_cv.pdf">CV,</a> <a href="https://www.linkedin.com/in/olli-suutari/">LinkedIn,</a> <a href="https://github.com/Olli-Suutari"> Github</a> or message me: <a href="mailto:suutari.olli@gmail.com" target="_top"> suutari.olli@gmail.com</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      