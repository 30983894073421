import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2 tabIndex="0" className="about-title" style={{
      "letterSpacing": ".3em"
    }}>Skillset</h2>
    <p tabIndex="0">
    6 years of formal training in the form of two degrees and more than 5 years of professional experience; a lovely package of theoretical and technical know-how.
    </p>
    <p tabIndex="0">
    My goal is to create awesome and robust UI/UX without forgetting the accessibility side of things.
    </p>
    <p tabIndex="0">
    HTML, CSS, JavaScript, Node, React and MongoDB are my current go-to stack, but I've also accumulated knowledge in things such as:
    </p>
    <ul tabIndex="0">
    <li>Angular and Vue</li>
    <li>SQL / relational databases</li>
    <li>PHP, Python and C#</li>
    <li>Docker and Virtual Machines</li>
    <li>Unit testing and test automation</li>
    <li>Bootstrap, SASS and LESS</li>
    <li>WordPress and Drupal</li>
    <li>And so forth... Contact me for further details :)</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      